import React from 'react';
import { OverlayDrawerComponentProps } from '@sharefiledev/sharefile-appshell';

const LazyClientAssociateFolderDrawer = React.lazy(
	() => import('../components/ClientAssociateFoldersDrawer')
);
export const AssociateFolderToClientDrawer: React.FC<
	OverlayDrawerComponentProps<'urn:sfdrawer:client-mgt-pilet:client-associate-folder:intake'>
> = ({ piral, options, cancelInteraction, completeInteraction }) => {
	if (
		!options ||
		!options.versionAgnosticId ||
		!options.parentId ||
		!options.versionId ||
		options.isContainer === undefined
	) {
		return <div data-testid="client-associate-folder" />;
	}

	return (
		<React.Suspense
			fallback={<div data-testid="loading-client-associate-folders-drawer" />}
		>
			<LazyClientAssociateFolderDrawer
				initialParameters={{
					versionAgnosticId: options?.versionAgnosticId,
					parentId: options?.parentId,
					versionId: options?.versionId,
					isContainer: options?.isContainer,
					userRIDs: options?.userRIDs,
					piral,
					cancelInteraction,
					completeInteraction,
				}}
			/>
		</React.Suspense>
	);
};
