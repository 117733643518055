import React from 'react';
import type { ExtensionComponentProps } from '@sharefiledev/sharefile-appshell';
import { ClientManagementConfigurationExtensionParams } from '../components/Configuration';

const LazyClientManagementConfigurationProvider = React.lazy(
	() => import('../components/Configuration')
);
/**
 * This extension is intended to provide an extension slot where
 * client management feature configuration can be performed.
 *
 * Examples could include:
 *
 * * Create Service Teams
 * * Custom attributes on clients
 * * Custom attributes on client contacts
 * * Custom attributes on team members
 * * Modify the view for all employees
 */
export const AsyncClientManagementConfiguration: React.FC<
	ExtensionComponentProps<ClientManagementConfigurationExtensionParams>
> = props => {
	return (
		<React.Suspense
			fallback={<div data-testid="loading-client-management-configuration" />}
		>
			<LazyClientManagementConfigurationProvider {...props} />
		</React.Suspense>
	);
};
