import React from 'react';

const LazyLinkFoldersToClient = React.lazy(
	() => import('./LinkFoldersToClientComponent')
);

const LinkFoldersToClientsModal: React.FC = () => (
	<React.Suspense fallback={<div data-testid="loading-link-folders-to-client" />}>
		<LazyLinkFoldersToClient />
	</React.Suspense>
);

export default LinkFoldersToClientsModal;
